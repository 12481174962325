import { ChangeDetectionStrategy, Component, HostBinding, InputSignal, Signal, computed, input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { UploadFormStatus } from '../upload-form/upload-form.component';

type LabelUploadStatusConfig = {
  icon: string;
  uploadInfoLabel: string;
  uploadInfoSubLabel: string;
  uploadButtonLabel: string;
};

@Component({
  selector: 'mp-file-upload-label',
  standalone: true,
  templateUrl: './file-upload-label.component.html',
  styleUrl: './file-upload-label.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatButtonModule, MatIconModule],
})
export class FileUploadLabelComponent {
  readonly componentCssClass = 'mp-file-upload-label';

  @HostBinding('class')
  get class(): string {
    return `${this.componentCssClass} ${this.getComponentStateCssClass()}`;
  }

  readonly buttonLabel: InputSignal<string> = input.required<string>();
  readonly iconName: InputSignal<string> = input.required<string>();
  readonly acceptedFileTypes: InputSignal<string> = input<string>('*');
  readonly status: InputSignal<UploadFormStatus> = input<UploadFormStatus>(UploadFormStatus.PreUpload);
  readonly errorMessage: InputSignal<string | undefined> = input<string>();

  protected readonly labelUploadStatusConfig: Signal<LabelUploadStatusConfig | undefined> = computed(() =>
    this.getUploadLabelConfig(),
  );

  protected readonly UploadFormStatus = UploadFormStatus;

  private getComponentStateCssClass(): string {
    let statusCssModifier = '';
    switch (this.status()) {
      case UploadFormStatus.Success: {
        statusCssModifier = '--success';
        break;
      }
      case UploadFormStatus.Error: {
        statusCssModifier = '--error';
        break;
      }
      default:
        break;
    }

    return statusCssModifier ? this.componentCssClass + statusCssModifier : '';
  }

  private getUploadLabelConfig(): LabelUploadStatusConfig | undefined {
    switch (this.status()) {
      case UploadFormStatus.PreUpload:
        return {
          icon: 'backup',
          uploadInfoLabel: this.acceptedFileTypes().toUpperCase(),
          uploadInfoSubLabel: 'max. 500MB pro Datei',
          uploadButtonLabel: this.buttonLabel(),
        };

      case UploadFormStatus.Success:
        return {
          icon: 'download_done',
          uploadInfoLabel: 'Erfolgreich hochgeladen!',
          uploadInfoSubLabel: 'Du erhältst in Kürze eine Email.',
          uploadButtonLabel: `Weitere ${this.buttonLabel()}`,
        };

      case UploadFormStatus.Error:
        return {
          icon: 'warning',
          uploadInfoLabel: 'Fehlgeschlagen!',
          uploadInfoSubLabel: this.errorMessage() ?? '',
          uploadButtonLabel: `Erneut ${this.buttonLabel()}`,
        };

      default:
        return undefined;
    }
  }
}
