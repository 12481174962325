import { ChangeDetectionStrategy, Component, InputSignal, OutputEmitterRef, input, output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { ElementDirective } from '../core/element';

@Component({
  selector: 'mp-relation-list-item',
  standalone: true,
  templateUrl: './relation-list-item.component.html',
  styleUrl: './relation-list-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIconModule, MatButtonModule],
})
export class RelationListItemComponent extends ElementDirective {
  readonly removed: InputSignal<boolean> = input<boolean>(false);
  readonly addItem: InputSignal<boolean> = input<boolean>(false);

  readonly remove: OutputEmitterRef<void> = output<void>();
  readonly reverse: OutputEmitterRef<void> = output<void>();

  constructor() {
    super();
    this.class = 'mp-relation-list-item';
  }
}
