<div
  ngx-dropzone
  #fileUploadDropzone
  data-test="upload-form__dropzone"
  class="dropzone"
  [class]="{
    'dropzone--success': status === UploadFormStatus.Success,
    'dropzone--error': status === UploadFormStatus.Error,
    'dropzone--upload': status === UploadFormStatus.Uploading
  }"
  [multiple]="allowMultipleUploads"
  [accept]="acceptedFileTypes"
  [maxFileSize]="maxFileBytes"
  [disabled]="disabled"
  (change)="onChange($event)"
  (click)="onDropzoneClick()"
>
  @if (status !== UploadFormStatus.Uploading) {
    <div class="dropzone__content" data-test="upload-form__dropzone-content">
      <ng-content />
    </div>
  } @else {
    <div class="uploading-indicator">
      <mat-progress-bar
        data-test="upload-form__uploading-indicator"
        [mode]="uploadingProgress !== undefined ? 'determinate' : 'indeterminate'"
        [value]="uploadingProgress ?? 0"
      />
    </div>
  }
</div>
