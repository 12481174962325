import { ChangeDetectionStrategy, Component, InputSignal, OutputEmitterRef, input, output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { ChipProperties } from '../chip-properties';

@Component({
  selector: 'mp-custom-chip',
  standalone: true,
  templateUrl: './custom-chip.component.html',
  styleUrl: './custom-chip.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIconModule, MatButtonModule],
})
export class CustomChipComponent {
  readonly chip: InputSignal<ChipProperties> = input.required<ChipProperties>();
  readonly small: InputSignal<boolean> = input<boolean>(false);
  readonly removable: InputSignal<boolean> = input<boolean>(false);

  readonly remove: OutputEmitterRef<string | undefined> = output<string | undefined>();

  removeChip(value?: string): void {
    this.remove.emit(value);
  }
}
