<button class="expansion-button" mat-button>
  <span class="truncate">
    <span class="expansion-button__text">
      {{ expandButtonLabel() }}
    </span>
    <span class="expansion-button__expansion-text">
      {{ isPanelExpanded() ? 'ausblenden' : 'anzeigen' }}
    </span>
  </span>
  <mat-icon iconPositionEnd>{{ isPanelExpanded() ? 'expand_less' : 'expand_more' }}</mat-icon>
</button>
