<div class="dropzone-label">
  <mat-icon class="dropzone-label__icon material-icons-outlined" data-test="file-upload-label__dropzone-label-icon">
    {{ labelUploadStatusConfig()?.icon }}
  </mat-icon>
  <div class="dropzone-label__text">
    <span class="dropzone-label__text--bold" data-test="file-upload-label__dropzone-label-header">
      {{ labelUploadStatusConfig()?.uploadInfoLabel }}
    </span>
    <span data-test="file-upload-label__dropzone-label-subheader">
      {{ labelUploadStatusConfig()?.uploadInfoSubLabel }}
    </span>
  </div>
</div>

<button class="upload-button" mat-raised-button>
  <mat-icon class="upload-button__icon material-icons-outlined">{{ iconName() }}</mat-icon>
  <span data-test="file-upload-label__upload-button-text">
    {{ labelUploadStatusConfig()?.uploadButtonLabel }}
  </span>
</button>
