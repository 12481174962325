import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Optional,
  Output,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgxDropzoneChangeEvent, NgxDropzoneComponent, NgxDropzoneModule } from 'ngx-dropzone';
import { RejectedFile } from 'ngx-dropzone/lib/ngx-dropzone.service';
import { filter } from 'rxjs';

import { UploadSchedulerService, UploadTriggerOptions } from './upload-scheduler.service';

export enum UploadFormStatus {
  PreUpload = 'PreUpload',
  Uploading = 'Uploading',
  Success = 'Success',
  Error = 'Error',
}

export interface FilesChangeEvent<T = UploadTriggerOptions> {
  addedFiles: File[];
  rejectedFiles: RejectedFile[];
  uploadTriggerOptions?: T;
}

@Component({
  selector: 'mp-upload-form',
  templateUrl: './upload-form.component.html',
  styleUrl: './upload-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgxDropzoneModule, MatProgressBarModule],
})
export class UploadFormComponent {
  @HostBinding('class') readonly class = 'mp-upload-form';

  @ViewChild('fileUploadDropzone') fileUploadDropzone!: NgxDropzoneComponent;

  @Input() allowMultipleUploads = false;
  @Input() acceptedFileTypes = '*';
  @Input() maxFileBytes = 0;
  @Input() disabled = false;
  @Input() status: UploadFormStatus = UploadFormStatus.PreUpload;
  @Input() uploadingProgress?: number;

  @Output() readonly filesChange: EventEmitter<FilesChangeEvent> = new EventEmitter<FilesChangeEvent>();

  readonly UploadFormStatus = UploadFormStatus;

  private uploadTriggerOptions?: UploadTriggerOptions;

  private fileSelectorOpenedByTrigger = false;

  constructor(@Optional() private readonly uploadSchedulerService?: UploadSchedulerService) {
    this.uploadSchedulerService?.uploadTrigger$
      .pipe(
        filter(() => !this.disabled),
        takeUntilDestroyed(),
      )
      .subscribe({
        next: (uploadTriggerOptions) => this.showFileSelector(uploadTriggerOptions),
      });
  }

  onChange({ addedFiles, rejectedFiles }: NgxDropzoneChangeEvent): void {
    this.filesChange.emit({
      addedFiles,
      rejectedFiles,
      uploadTriggerOptions: this.uploadTriggerOptions,
    });
    this.uploadTriggerOptions = undefined;
  }

  showFileSelector(uploadTriggerOptions?: UploadTriggerOptions): void {
    this.fileSelectorOpenedByTrigger = true;
    this.uploadTriggerOptions = uploadTriggerOptions;
    this.fileUploadDropzone.showFileSelector();
  }

  /**
   * Respond to a click event on the drop zone which can be
   * triggered programmatically (showFileSelector()) or by user click.
   */
  onDropzoneClick(): void {
    // If event is triggered programmatically the trigger options should not be cleared
    if (!this.fileSelectorOpenedByTrigger) {
      this.uploadTriggerOptions = undefined;
      return;
    }
    this.fileSelectorOpenedByTrigger = false;
  }
}
