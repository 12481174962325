import { animate, state, style, transition, trigger } from '@angular/animations';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { LayoutModule } from '@angular/cdk/layout';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnDestroy, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

import { HeaderElementDirective } from '@core/shared/util';

import { ElementDirective } from '../core/element';

@Component({
  selector: 'mp-view',
  standalone: true,
  templateUrl: './view.component.html',
  styleUrl: './view.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgClass,
    NgTemplateOutlet,
    RouterModule,
    LayoutModule,

    MatIconModule,
    MatButtonModule,

    HeaderElementDirective,
  ],
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          transform: 'translateX(0%)',
        }),
      ),
      state(
        'closed',
        style({
          transform: 'translateX(100%)',
        }),
      ),
      transition('open => closed', [animate('0.15s ease-in-out')]),
      transition('closed => open', [animate('0.15s ease-in-out')]),
    ]),
  ],
})
export class ViewComponent extends ElementDirective implements OnDestroy {
  @Input()
  @HostBinding('class.mp-view--custom')
  isCustomHeader = false;

  @Input() viewTitle?: string;
  @Input() submittable = false;

  @Input()
  get readonly() {
    return this._readonly;
  }

  set readonly(value: BooleanInput) {
    this._readonly = coerceBooleanProperty(value);
  }

  private _readonly = false;

  @Output() readonly cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() readonly send: EventEmitter<void> = new EventEmitter<void>();

  isContextBarActive = false;

  constructor() {
    super();

    this.class = 'mp-view';
  }

  ngOnDestroy(): void {
    if (this.cancel) {
      this.cancel.complete();
    }
    if (this.send) {
      this.send.complete();
    }
  }
}
