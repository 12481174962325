<div class="entity-item">
  <div class="entity-item-head">
    <!--
      Status-Slot:
      Takes <mp-entity-status>
    -->
    <div class="entity-item__slot-status">
      <ng-content select="mp-entity-item-status" />
    </div>

    <!--
      Drag-Handle-Slot:
      Takes <mp-drag-handle>
    -->
    <div class="entity-item__slot-drag-handle">
      <ng-content select="mp-drag-handle" />
    </div>

    <!--
      Icon-Slot:
      Takes <mp-entity-item-icon> or <mat-icon>
    -->
    <div class="entity-item__slot-icon">
      <ng-content select="mat-icon" />
      <ng-content select="mp-entity-item-icon" />
    </div>

    <!--
      Content-Slot:
      Takes <mp-entity-item-content> or any other tag
    -->
    <div class="entity-item__slot-content">
      <ng-content />
      <ng-content select="mp-entity-item-content" />
    </div>

    <!--
      Actions-Slot:
      Takes <mp-entity-item-actions> or any element with the 'entitiy-item-slots-actions' attribute
    -->
    <div class="entity-item__slot-actions">
      <ng-content select="[entitiy-item-slots-actions]" />
      <ng-content select="mp-entity-item-actions" />
    </div>
  </div>

  <!--
    Footer-Slot:
    Takes <mp-entity-item-footer>
  -->
  <div class="entity-item__slot-footer">
    <ng-content select="mp-entity-item-footer" />
  </div>
</div>
