<form [formGroup]="form">
  <mat-form-field>
    <mat-label>Aktuelles Passwort</mat-label>
    <input matInput type="password" formControlName="currentPassword" />
  </mat-form-field>

  <mat-form-field>
    <mat-label>Neues Passwort</mat-label>
    <input
      #passwordInput
      matInput
      type="password"
      formControlName="newPassword"
      cdkOverlayOrigin
      #trigger="cdkOverlayOrigin"
      (focus)="isRequirementsOverlayOpen.set(true)"
      (blur)="isRequirementsOverlayOpen.set(false)"
    />
  </mat-form-field>

  <mat-form-field>
    <mat-label>Neues Passwort bestätigen</mat-label>
    <input matInput type="password" formControlName="newPasswordConfirmation" />

    @if (form.hasError('passwordsDontMatch')) {
      <mat-error>Die Passwörter stimmen nicht überein.</mat-error>
    }
  </mat-form-field>

  <button mat-flat-button type="button" color="accent" (click)="onButtonClick()" [disabled]="form.invalid">
    Passwort ändern
  </button>
</form>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isRequirementsOverlayOpen()"
  [cdkConnectedOverlayMinWidth]="passwordInputRef.nativeElement.getBoundingClientRect().width"
>
  <div class="mp-change-password-input__requirements">
    @for (requirement of passwordRequirements(); track $index) {
      <mat-option class="requirement">
        <div class="requirement__row" [class]="{ 'requirement__row--fulfilled': requirement.isFulfilled }">
          <mat-icon class="requirement__row-icon">{{ requirement.isFulfilled ? 'check_circle' : 'error' }}</mat-icon>
          <small class="requirement__row-label">{{ requirement.label }}</small>
        </div>
      </mat-option>
    }
  </div>
</ng-template>
