import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  InputSignal,
  OutputEmitterRef,
  Signal,
  computed,
  input,
  output,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRouteSnapshot, NavigationEnd, Router, RouterLink } from '@angular/router';
import { LetDirective } from '@ngrx/component';
import { Observable, filter, map, startWith } from 'rxjs';

import {
  FeatureFlags,
  HeaderContentService,
  HeaderElementPlacement,
  HeaderElementsMap,
  ThemeAssetsPathPipe,
} from '@core/shared/util';

@Component({
  selector: 'mp-app-header',
  standalone: true,
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AsyncPipe,
    NgClass,
    NgTemplateOutlet,
    RouterLink,
    LetDirective,

    MatButtonModule,
    MatIconModule,

    ThemeAssetsPathPipe,
  ],
})
export class AppHeaderComponent {
  private readonly _activatedRouteTitle: Signal<string | undefined> = toSignal(
    this.buildInternalActivatedRouteTitleObservable(),
  );

  readonly featureFlags: InputSignal<FeatureFlags> = input.required<FeatureFlags>();
  readonly showAppLogo: InputSignal<boolean> = input.required<boolean>();
  readonly appTitle: InputSignal<string> = input.required<string>();

  readonly sidenavToggle: OutputEmitterRef<void> = output<void>();

  readonly headerElementsMap$: Observable<HeaderElementsMap> = this.headerContentService.headerElementsMap$;

  readonly activeRouteTitle: Signal<string> = computed(() => {
    return this._activatedRouteTitle() || this.appTitle();
  });

  readonly headerElementPlacements: HeaderElementPlacement[] = ['left', 'center', 'right'];

  constructor(
    private readonly headerContentService: HeaderContentService,
    private readonly router: Router,
  ) {}

  toggleSidenav(): void {
    this.sidenavToggle.emit();
  }

  private getActiveModuleRoute(snapshot: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
    // Active module route is always the third child from application root due to the routing structure
    return snapshot.firstChild?.firstChild?.firstChild || snapshot;
  }

  private buildInternalActivatedRouteTitleObservable(): Observable<string | undefined> {
    return this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      startWith({}),
      map(() => this.getActiveModuleRoute(this.router.routerState.snapshot.root).title),
    );
  }
}
