import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  HostBinding,
  HostListener,
  InputSignal,
  OnInit,
  OutputEmitterRef,
  input,
  output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'mp-basic-flyout',
  standalone: true,
  templateUrl: './basic-flyout.component.html',
  styleUrl: './basic-flyout.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatButtonModule, MatIconModule],
})
export class BasicFlyoutComponent implements OnInit {
  @HostBinding('class') readonly class = 'mp-basic-flyout';

  readonly title: InputSignal<string> = input.required<string>();

  readonly closed: OutputEmitterRef<void> = output<void>();

  @HostListener('document:keydown', ['$event'])
  handleKeydown(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.close();
    }
  }

  constructor(
    private readonly router: Router,
    private readonly destroyRef: DestroyRef,
  ) {}

  close(): void {
    this.closed.emit();
  }

  ngOnInit(): void {
    this.router.events.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: (event) => {
        if (event instanceof NavigationStart) {
          this.close();
        }
      },
    });
  }
}
