import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';

import { EntityIcons } from '../entity-icons';
import { EntityType } from '../entity-type';

import { EntityItemActionButtonComponent } from './entity-item-action-button/entity-item-action-button.component';

@Component({
  selector: 'mp-entity-item',
  standalone: true,
  templateUrl: './entity-item.component.html',
  styleUrl: './entity-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatChipsModule, MatIconModule, EntityItemActionButtonComponent],
})
export class EntityItemComponent {
  @HostBinding('class') readonly class = 'mp-entity-item';
  @HostBinding('class.item--removed') removed = false;

  @Input() @HostBinding('class.item--hidden') hidden = false;

  @Input() chipsLabels: string[] = [];

  @Input() id?: string;
  @Input() label?: string;
  @Input() meta?: string;

  @Input() icon?: string;

  /**
   * Convenience property that sets the components {@link icon} based on a use-case.
   *
   * All this does is basically lookup the required material icon from the {@link EntityIcons} map.
   * */
  @Input() set type(entityType: EntityType) {
    if (!this.icon && entityType) {
      this.icon = EntityIcons[entityType];
    }
  }

  @Input()
  get editMode() {
    return this._editMode;
  }
  set editMode(value: BooleanInput) {
    this._editMode = coerceBooleanProperty(value);
  }
  private _editMode = false;

  @Output() readonly remove = new EventEmitter<string>();
  @Output() readonly undo = new EventEmitter<string>();

  removeClicked() {
    this.removed = true;
    this.remove.emit(this.id);
  }

  undoClicked() {
    this.removed = false;
    this.undo.emit(this.id);
  }
}
