import { ChangeDetectionStrategy, Component, InputSignal, OutputEmitterRef, input, output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'mp-entity-item-action-button',
  standalone: true,
  templateUrl: './entity-item-action-button.component.html',
  styleUrl: './entity-item-action-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatButtonModule, MatIconModule],
})
export class EntityItemActionButtonComponent {
  readonly type: InputSignal<'button' | 'link'> = input<'button' | 'link'>('button');

  readonly icon: InputSignal<string> = input.required<string>();

  readonly link: InputSignal<string> = input<string>('');

  readonly actionClick: OutputEmitterRef<void> = output<void>();

  emitActionClick(): void {
    this.actionClick.emit();
  }
}
