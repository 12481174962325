import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';

import { EntityListHeadActionButtonComponent } from '../entity-items/entity-list-head/entity-list-head-action-button/entity-list-head-action-button.component';
import { EntityListHeadComponent } from '../entity-items/entity-list-head/entity-list-head.component';

import { AssignedOrganization } from './assigned-organization';
import { OrganizationSelectorColumnName } from './organization-selector-column-name';

@Component({
  selector: 'mp-organization-selector',
  standalone: true,
  templateUrl: './organization-selector.component.html',
  styleUrl: './organization-selector.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatCardModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,

    EntityListHeadComponent,
    EntityListHeadActionButtonComponent,
  ],
})
export class OrganizationSelectorComponent {
  searchTerm = '';

  // Input is for example Organizations[] and what will be put into should be handled by parent component
  // to call different endpoints (Sortimente, Plattformkatalog)
  @Input() selectorItems: AssignedOrganization[] = [];

  // Optional header informations
  @Input() headerTitle = '';
  @Input() headerIcon = '';
  @Input() emptyText?: string;

  @Input() hideActions = false;

  get selectorCols(): OrganizationSelectorColumnName[] {
    return this.hideActions ? ['name'] : ['name', 'actions'];
  }

  // Outputs for the parent component to handle what to do
  @Output() readonly deleteToggleClicked = new EventEmitter<AssignedOrganization>();
  @Output() readonly searched = new EventEmitter<string>();
  @Output() readonly addItemsClicked = new EventEmitter<void>();

  deleteOrToggleItem(selection: AssignedOrganization): void {
    this.deleteToggleClicked.emit(selection);
  }

  emitSearched(searchTerm: string | undefined): void {
    this.searched.emit(searchTerm);
  }

  handleAddItemsClicked(): void {
    this.addItemsClicked.emit();
  }

  getOrganizationId(_index: number, organization: AssignedOrganization): string {
    return organization.organizationId;
  }
}
