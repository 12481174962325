import { ChangeDetectionStrategy, Component, OutputEmitterRef, output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'mp-navigate-back-button',
  standalone: true,
  templateUrl: './navigate-back-button.component.html',
  styleUrls: ['./navigate-back-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIconModule, MatButtonModule],
})
export class NavigateBackButtonComponent {
  readonly navigateBackClick: OutputEmitterRef<void> = output<void>();

  navigateBack(): void {
    this.navigateBackClick.emit();
  }
}
