import { SelectionChange, SelectionModel } from '@angular/cdk/collections';
import { OverlayModule } from '@angular/cdk/overlay';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { AsyncPipe, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Inject, TemplateRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LetDirective } from '@ngrx/component';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, map, shareReplay, startWith } from 'rxjs/operators';

import { FlyoutAnimation } from '../../component/basic-flyout/basic-flyout-animation';
import { BasicFlyoutComponent } from '../../component/basic-flyout/basic-flyout.component';
import { FlyoutComponent } from '../../component/flyout-component';
import { OVERLAY_DATA } from '../../custom-overlay/custom-overlay-tokens';
import { SelectionItem } from '../selection-item/selection-item';
import { SelectorFlyoutData } from '../selector-flyout-config';

import { SelectorItemComponent } from './item/selector-item.component';
import { DefaultSearchLambdas, DefaultSortLambdas } from './lambdas';
import { SelectAllFlyoutElementsButtonComponent } from './select-all-flyout-elements-button/select-all-flyout-elements-button.component';

@Component({
  selector: 'mp-flyout-selector',
  standalone: true,
  templateUrl: './selector.component.html',
  styleUrl: './selector.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: FlyoutAnimation.Named('openClose'),
  imports: [
    NgIf,
    NgTemplateOutlet,
    NgFor,
    AsyncPipe,
    ReactiveFormsModule,
    LetDirective,
    ScrollingModule,
    OverlayModule,

    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatTooltipModule,

    BasicFlyoutComponent,
    SelectorItemComponent,
    SelectAllFlyoutElementsButtonComponent,
  ],
})
export class SelectorComponent<ItemReturnType> implements FlyoutComponent<Array<ItemReturnType>> {
  @HostBinding('class') readonly class = 'mp-flyout-selector';
  @HostBinding('@openClose') animationState: FlyoutAnimation.State = 'open';

  readonly template?: TemplateRef<unknown>;

  readonly displayedItems$: Observable<Array<ItemReturnType>>;
  private readonly items$: Observable<Array<ItemReturnType>>;
  private readonly selectionModel: SelectionModel<ItemReturnType>;

  private readonly searchTerm$: Observable<string>;
  readonly searchField: UntypedFormControl = new UntypedFormControl('');

  readonly isAllItemsSelected$: Observable<boolean>;

  private readonly _afterClosed$ = new Subject<Array<ItemReturnType> | undefined>();
  readonly afterClosed$ = this._afterClosed$.pipe(delay(FlyoutAnimation.AnimationDuration));

  readonly useVirtualScroll: boolean = this.data.useVirtualScroll ?? false;

  readonly virtualScrollItemSize: number = this.data.virtualScrollItemSize ?? 70;

  readonly showSelectAllButton: boolean = this.data.showSelectAllButton ?? false;

  get title(): string {
    return this.data.title;
  }

  /* Selection Properties */

  get selectionChange$(): Observable<SelectionChange<ItemReturnType>> {
    return this.selectionModel.changed.asObservable();
  }

  get selection(): Array<ItemReturnType> {
    return this.selectionModel.selected;
  }

  constructor(@Inject(OVERLAY_DATA) private readonly data: SelectorFlyoutData<ItemReturnType>) {
    this.template = this.data.itemTemplate;

    this.selectionModel = new SelectionModel<ItemReturnType>(this.data.multiple, this.data.initiallySelected);

    this.items$ = this.buildItems$(data.items);
    this.searchTerm$ = this.buildSearchTerm$(this.searchField);
    this.displayedItems$ = this.buildDisplayedItems$(this.items$, this.searchTerm$, this.selectionChange$);

    this.isAllItemsSelected$ = combineLatest([this.items$, this.selectionChange$]).pipe(
      map(([allItems]) => allItems.length === this.selection.length),
    );
  }

  private buildItems$(
    items$: Array<ItemReturnType> | Observable<Array<ItemReturnType>>,
  ): Observable<Array<ItemReturnType>> {
    const itemsObservable$: Observable<Array<ItemReturnType>> =
      items$ instanceof Observable ? items$ : new BehaviorSubject(items$);
    return itemsObservable$.pipe(shareReplay(1));
  }

  private buildDisplayedItems$(
    items$: Observable<Array<ItemReturnType>>,
    searchTerm$: Observable<string>,
    selectionChange$: Observable<SelectionChange<ItemReturnType>>,
  ): Observable<Array<ItemReturnType>> {
    return combineLatest([items$, searchTerm$, selectionChange$.pipe(startWith(null))]).pipe(
      map(([items, searchTerm]) => this.filterItems(items, searchTerm)),
      map((filteredItems) => this.sortItems(filteredItems)),
      takeUntilDestroyed(),
    );
  }

  private buildSearchTerm$(searchField: UntypedFormControl): Observable<string> {
    return searchField.valueChanges.pipe(
      debounceTime(500),
      map((searchTerm: string) => searchTerm.trim()),
      distinctUntilChanged(),
      startWith(this.searchField.value),
      takeUntilDestroyed(),
    );
  }

  cancel(): void {
    this.close();
  }

  confirm(): void {
    this.close(this.selection);
  }

  close(returnValue?: Array<ItemReturnType>): void {
    this.animationState = 'closed';

    this._afterClosed$.next(returnValue);
    this._afterClosed$.complete();
  }

  isSelected(item: ItemReturnType): boolean {
    return this.selectionModel.isSelected(item);
  }

  isNoItemsSelected(): boolean {
    return this.selectionModel.isEmpty();
  }

  toggleSelect(item: ItemReturnType): void {
    this.selectionModel.toggle(item);
  }

  selectItems(items: ItemReturnType[]): void {
    this.selectionModel.select(...items);
  }

  deselectAllItems(): void {
    this.selectionModel.clear();
  }

  clearSearch(): void {
    this.searchField.setValue('');
  }

  getIsItemDisabled(item: ItemReturnType): boolean {
    return this.data.isDisabledLambda?.(item) ?? false;
  }

  getItemTooltipText(item: ItemReturnType): string {
    return this.data.itemTooltipLambda?.(item) ?? '';
  }

  private filterItems(items: Array<ItemReturnType>, searchTerm: string): Array<ItemReturnType> {
    const selectedItems = this.selectionModel.selected;
    const unselectedItems = items.filter((item) => !selectedItems.includes(item));

    const defaultSearchLambda =
      items[0] instanceof SelectionItem ? (DefaultSearchLambdas.selectionItem as any) : DefaultSearchLambdas.any;

    return [
      ...selectedItems,
      ...unselectedItems.filter((item) => (this.data.searchLambda ?? defaultSearchLambda)(item, searchTerm)),
    ];
  }

  private sortItems(items: Array<ItemReturnType>): Array<ItemReturnType> {
    const selectedItems = this.selectionModel.selected;
    const unselectedItems = items.filter((item) => !selectedItems.includes(item));

    const defaultSortLambda =
      items[0] instanceof SelectionItem ? (DefaultSortLambdas.selectionItem as any) : DefaultSortLambdas.unsorted;

    return [
      ...selectedItems.sort(this.data.sortLambda ?? defaultSortLambda),
      ...unselectedItems.sort(this.data.sortLambda ?? defaultSortLambda),
    ];
  }
}
