import { ChangeDetectionStrategy, Component, InputSignal, input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'mp-expand-button',
  standalone: true,
  templateUrl: './expand-button.component.html',
  styleUrl: './expand-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatButtonModule, MatIconModule],
})
export class ExpandButtonComponent {
  readonly isPanelExpanded: InputSignal<boolean> = input.required<boolean>();

  readonly expandButtonLabel: InputSignal<string> = input.required<string>();
}
