<mat-accordion #accordion="matAccordion" multi>
  <mp-entity-list-head [count]="count" [editMode]="editMode" (searched)="handleSearchTerm($event)">
    <button mat-icon-button class="entity-list-group__collapse-btn" (click)="toggleOpenCloseAllExpanders(accordion)">
      <mat-icon>{{ allExpanded ? 'unfold_more' : 'unfold_less' }}</mat-icon>
    </button>
  </mp-entity-list-head>

  @for (list of listGroups; track $index) {
    <mat-expansion-panel [expanded]="list.expanded">
      <mat-expansion-panel-header class="entity-list-group__header">
        <mat-panel-title class="entity-list-group__title">
          @if (list.type ?? type) {
            <mat-icon class="entity-list-group__icon material-icons-outlined">
              {{ list.icon ?? icon | entityIcon: list.type ?? type }}
            </mat-icon>
          }
          <span class="entity-list-group__title-text truncate" [title]="list.label">{{ list.label }}</span>
        </mat-panel-title>

        @if (list.meta) {
          <mat-panel-description class="entity-list-group__meta">
            {{ list.meta }}
          </mat-panel-description>
        } @else {
          <mat-panel-description class="entity-list-group__meta">
            {{ (list.count$ | async)! | autoSingularOrPlural: 'Ergebnis' : 'Ergebnisse' }}
          </mat-panel-description>
        }

        <mat-panel-description class="entity-list-group__actions">
          @if (list.editMode) {
            <button mat-button color="accent" (click)="addClicked(list.id, $event)">
              <mat-icon class="entity-list-group__add-action-button-icon">add</mat-icon>
            </button>
          }
        </mat-panel-description>
      </mat-expansion-panel-header>

      <ng-container [ngTemplateOutlet]="list.template" />
    </mat-expansion-panel>
  }
</mat-accordion>
